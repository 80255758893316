<template>
  <b-modal @change="change" :visible="visible" hide-footer hide-header centered>
    <div class="confirmation p-2">
      <h3 class="text-center px-4 pt-2" v-if="title.length">{{ title }}</h3>

      <div class="text-center px-4 pt-2 pb-3 mb-4 text">{{ text }}</div>

      <div class="row">
        <div class="col pr-4">
          <button
            class="btn btn-block btn-outline-secondary btn-lg"
            @click="cancel"
          >
            {{ cancelButtonText }}
          </button>
        </div>
        <div class="col pl-4">
          <button
            class="btn btn-block btn-lg"
            :class="confirmButtonClass"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      defaul: false
    },
    text: {
      type: String,
      default: "Tem certeza?"
    },
    title: {
      type: String,
      default: ""
    },
    cancelButtonText: {
      type: String,
      default: "Cancelar"
    },
    confirmButtonText: {
      type: String,
      default: "Confirmar"
    },
    confirmButtonClass: {
      type: String,
      default: "btn-danger"
    }
  },
  model: {
    prop: "visible",
    event: "change"
  },
  methods: {
    change(val) {
      this.$emit("change", val);
    },
    cancel() {
      this.$emit("cancel");
      this.change(false);
    },
    confirm() {
      this.$emit("confirm");
      this.change(false);
    }
  }
};
</script>

<style lang="scss">
.confirmation {
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  .text {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
