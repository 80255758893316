<template>
  <div class="home-course-card card border-0 shadow">
    <div class="card-body w-100">
      <div
        class="card-img"
        :style="`background-image: url(${course.cover})`"
      ></div>
      <div class="text-secondary small">
        <strong>{{ course.count_lessons }} AULAS</strong>
      </div>
      <h6 class="font-weight-bold mb-0">{{ course.title }}</h6>
    </div>
    <div
      class="card-body pt-0 w-100 bg-transparent border-0 d-flex justify-content-end align-items-end"
    >
      <router-link
        :to="{
          name: 'course',
          params: {
            ...course
          }
        }"
        class="btn btn-danger px-4 stretched-link"
      >
        <span class="mr-2">Acessar</span>
        <i class="icon-arrow-right lead"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    progress() {
      return parseInt(this.course.progress);
    }
  }
};
</script>

<style lang="scss" scoped>
.home-course-card {
  text-align: left;
  padding: 0;
  border-radius: 16px;

  .card-img {
    height: 0;
    width: 100%;
    display: block;
    padding-bottom: 55%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  h3 {
    text-transform: uppercase;
  }

  .progress {
    height: 14px;
  }

  .progress-percent {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
  }

  .btn {
    position: initial;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
