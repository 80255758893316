import Vue from "vue";
import Router from "vue-router";
// import VueGtm from "vue-gtm";

import Home from "./views/Home.vue";
import DefaultTemplate from "./templates/Default.vue";

Vue.use(Router);

const vueRouter = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/buscar",
      name: "search",
      component: () => import("./views/Search.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/formacoes",
      name: "formations",
      component: () => import("./views/Formations.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/curso/:id/:slug",
      name: "course",
      component: () => import("./views/Course.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/curso/:id/:slug/aula/:lesson",
      name: "lesson",
      component: () => import("./views/Lesson.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/minha-conta",
      name: "account",
      component: () => import("./views/Account.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/acesso-bloqueado",
      name: "sale",
      component: () => import("./views/Sale.vue"),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/cadastro",
      name: "register",
      component: () => import("./views/Register.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("./views/ResetPassword.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/termos-de-uso",
      name: "terms",
      component: () => import("./views/Terms.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/NotFound.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "/inscricao",
      name: "inscricao",
      component: () => import("./views/Inscricao.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    }
  ]
});

// Vue.use(VueGtm, {
//   id: "GTM-TB4VDDF",
//   vueRouter
// });

export default vueRouter;
