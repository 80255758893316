export default {
  setQuiz(state, quiz) {
    state.quiz = quiz;
  },
  setQuizzes(state, quizzes) {
    state.quizzes = quizzes;
  },
  setTime(state, time) {
    state.time = time;
  }
};
