export default {
  getAll(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/courses/")
        .then(response => {
          context.commit("setCourses", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },

  getDetails(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/${id}`)
        .then(response => {
          context.commit("setCourse", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  setProgress(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/students/progress", data)
        .then(resolve)
        .catch(reject);
    });
  }
};
