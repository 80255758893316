<template>
  <action-dispatcher
    action="courses/getDetails"
    :parameters="course.id"
    ref="coursesDispatcher"
    @success="success"
    class="w-100"
  >
    <div class="home-progress-course-card card border-0 shadow p-3">
      <div class="w-100 form-row">
        <div class="col-lg-4">
          <div
            class="card-img"
            :style="`background-image: url(${course.cover})`"
          ></div>
        </div>

        <div class="col-lg-8">
          <div class="d-flex">
            <strong class="course-title text-secondary text-uppercase">{{
              course.title
            }}</strong>
            <div class="progress mb-1 rounded-pill flex-fill">
              <div
                class="progress-bar bg-danger rounded-pill"
                role="progressbar"
                :style="`width: ${course.progress}%`"
              ></div>
            </div>
            <div class="progress-percent">{{ progress }}%</div>
          </div>

          <template v-if="currentLesson">
            <h6>
              {{ currentLesson.name }}
            </h6>

            <router-link
              :to="{
                name: 'lesson',
                params: { ...course, lesson: currentLesson.id }
              }"
              class="btn btn-danger stretched-link float-right mt-2 px-4"
            >
              <span class="mr-2">Assistir</span>
              <i class="icon-arrow-right lead"></i>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      courseDetails: null
    };
  },
  computed: {
    progress() {
      return parseInt(this.course.progress);
    },
    currentLesson() {
      return (
        this.courseDetails &&
        this.courseDetails.lessons &&
        this.courseDetails.lessons.find(
          lesson => !lesson.progress || !lesson.progress.finished_at
        )
      );
    }
  },
  methods: {
    success(data) {
      this.courseDetails = data;
    },
    onClick() {
      this.$router.push({
        name: "course",
        params: {
          ...this.course
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-progress-course-card {
  text-align: left;
  padding: 0;
  border-radius: 16px;

  &:hover {
    opacity: 0.8;
  }

  .card-img {
    height: 0;
    width: 100%;
    display: block;
    padding-bottom: 55%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  h3 {
    text-transform: uppercase;
  }

  .progress {
    height: 14px;
    min-width: 100px;
  }

  .progress-percent {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
  }

  .btn {
    position: initial;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .course-title {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
    margin-right: 8px;
    overflow: hidden;
  }
}
</style>
