<template>
  <div class="template-default d-flex flex-column">
    <transition name="fade">
      <div class="blocking-bg d-lg-none" v-if="showMenu" @click="closeMenu" />
    </transition>

    <header class="shadow d-flex align-items-center">
      <div class="container-fluid d-flex align-items-center">
        <router-link :to="{ name: 'home' }" class="brand mr-5">
          <img src="@/assets/images/logo-small.png" />
        </router-link>

        <main-menu
          class="flex-fill d-none d-lg-flex"
          @logout="logoutDialog = true"
        />
      </div>
    </header>

    <button class="btn btn-menu d-lg-none" @click.stop="clickMenuButton">
      <i class="icon-menu menu d-none d-lg-block"></i>
      <i class="menu d-lg-none" :class="showMenu ? 'icon-x' : 'icon-menu'"></i>
    </button>

    <confirmation
      v-model="logoutDialog"
      @confirm="logout"
      text="Tem certeza que deseja sair?"
      confirm-button-text="Sair"
    />

    <action-dispatcher action="courses/getAll">
      <mobile-menu
        v-model="showMenu"
        @close="closeMenu"
        @logout="logoutDialog = true"
      />

      <main class="flex-fill">
        <div class="position-relative slot-wrapper">
          <slot v-if="!loading" />
          <b-spinner v-else />
        </div>
      </main>
    </action-dispatcher>

    <app-footer @logout="logoutDialog = true" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import MobileMenu from "../components/templates/MobileMenu";
import MainMenu from "../components/templates/MainMenu";
import AppFooter from "../components/templates/AppFooter";

export default {
  components: { MobileMenu, MainMenu, AppFooter },
  data() {
    return {
      showMenu: false,
      logoutDialog: false,
      menuButtonTimeout: null
    };
  },
  computed: {
    ...mapState(["loading"])
  },
  methods: {
    clickMenuButton() {
      this.setMenuVisibility(!this.showMenu);
    },
    setMenuVisibility(visibility) {
      if (this.menuButtonTimeout) {
        clearTimeout(this.menuButtonTimeout);
      }
      this.menuButtonTimeout = setTimeout(() => {
        this.showMenu = visibility;
      }, 150);
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    clickMenu() {
      this.closeMenu();
    },
    closeMenu() {
      this.showMenu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.template-default {
  min-height: 100vh;
  display: flex;

  @media screen and (max-width: 575px) {
    padding-top: 56px;
  }

  &.sale {
    background: #e63946;

    ::v-deep footer * {
      color: white !important;
    }
  }

  .btn-menu {
    position: fixed;
    width: 72px;
    top: 0;
    z-index: 4;
  }

  .blocking-bg {
    position: fixed;
    z-index: 3;
    background: rgba(0, 10, 20, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  i {
    font-size: 24px;
    min-width: 46px;

    &.menu {
      font-size: 32px;
      line-height: 44px;
      color: rgba(51, 51, 51, 0.6);
    }
  }

  .open-submenu {
    .icon-chevron-right {
      transform: rotate(90deg);
    }
  }

  main {
    width: 100%;
    position: relative;
  }

  header {
    min-height: 56px;
    max-height: 56px;
    position: sticky;
    top: 0;
    flex: 1 1 auto;
    z-index: 3;
    background: white;

    @media screen and (min-width: 992px) {
      z-index: 5;

      ::v-deep .btn {
        text-transform: uppercase !important;
        color: #8395a7 !important;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 575px) {
      right: 0;
      top: 0;
      left: 0;
      z-index: 3;
      position: fixed;
    }

    a {
      font-weight: bold;
      font-size: 12px;
      color: #8395a7;
    }

    .brand {
      @media screen and (max-width: 991px) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-weight: bold;
      }

      img {
        max-height: 20px;
      }
    }

    i {
      font-size: 20px;
    }
  }

  .slot-wrapper {
    transition: 0.3s padding ease;
  }

  .submenu {
    width: 100%;

    a {
      padding-left: 32px !important;
    }
  }
}
</style>
