<template>
  <footer class="templates-app-footer text-light">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="d-lg-none mb-4">
            <button
              type="button"
              class="btn btn-danger px-5"
              @click="openResearch"
            >
              Pesquisa de satisfação
            </button>
          </div>
          <main-menu @logout="$emit('logout')" class="mb-4" />
        </div>
        <div class="col-lg-3 col-md-6">
          <a
            href="http://universidadedointercambio.com/"
            target="_blank"
            class="btn"
            >Blog</a
          >
          <div class="item mt-3">Contato</div>
          <a
            href="mailto:contato@universidadedointercambio.com"
            target="_blank"
            class="btn"
            ><i class="icon-mail lead"></i
          ></a>
        </div>
        <div class="col-lg-6 d-none d-lg-block">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-danger px-5"
              @click="openResearch"
            >
              Pesquisa de satisfação
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex pt-4 align-items-end">
        <img src="../../assets/images/logo-white.png" />
        <div class="flex-fill"></div>
        <small>© 2021 Tentacle</small>
      </div>
    </div>
  </footer>
</template>

<script>
import MainMenu from "./MainMenu";

export default {
  components: { MainMenu },
  methods: {
    openResearch() {
      window.open("https://matheustomoto.typeform.com/to/m06Wjtf8", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.templates-app-footer {
  background: #576574;
  padding: 32px 0;

  ::v-deep .hide-footer {
    display: none;
  }

  ::v-deep a {
    color: white !important;
    padding: 8px 0 !important;
    min-height: 0;
    opacity: 0.8;
    display: block;
    text-align: left;

    &:hover {
      opacity: 1;
    }
  }

  .item {
    opacity: 0.8;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
