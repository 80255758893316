<template>
  <action-dispatcher action="courses/getAll">
    <div class="view-home py-5 container-fluid">
      <h5 class="mb-4">Bem-vindo(a), {{ $user.user.name }}!</h5>

      <header class="text-light text-center text-lg-left mb-5">
        <div class="row">
          <div class="col-lg-4 offset-lg-1 p-4 px-lg-0 mb-4 align-self-center">
            <h3>Sabe aquela oportunidade dos seus sonhos?</h3>
            <h5>A gente te ajuda a conquistar.</h5>
          </div>
          <div class="col-lg-4 offset-lg-1 px-5 px-lg-0">
            <img class="img-fluid" src="../assets/images/woman.png" />
          </div>
        </div>
        <img class="logo" src="../assets/images/logo-white.png" />
      </header>

      <h5 class="my-3">Continue de onde parou...</h5>

      <div class="row">
        <div
          v-for="(course, i) in progressCourses"
          :key="i"
          class="col-xl-4 col-md-6 mb-4 d-flex"
        >
          <progress-course-card class="flex-fill" :course="course" />
        </div>
      </div>

      <h5 class="my-3">Todos os cursos</h5>

      <div class="row">
        <div
          v-for="(course, i) in courses"
          :key="i"
          class="col-lg-3 col-md-4 col-sm-6 mb-4 d-flex"
        >
          <course-card class="flex-fill" :course="course" />
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import CourseCard from "@/components/home/CourseCard";
import ProgressCourseCard from "@/components/home/ProgressCourseCard";
import { mapState } from "vuex";

export default {
  components: { CourseCard, ProgressCourseCard },
  computed: {
    ...mapState("courses", ["courses"]),
    progressCourses() {
      return this.courses.filter(c => c.progress > 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.view-home {
  header {
    background: #54a0ff;
    border-radius: 16px;
    position: relative;

    h3 {
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    .logo {
      position: absolute;
      bottom: 24px;
      right: 24px;
    }
  }
}
</style>
