import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

import user from "./user";
import courses from "./courses";
import quiz from "./quiz";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  state,
  mutations,
  modules: {
    user,
    courses,
    quiz
  }
});
