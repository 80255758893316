import get from "lodash/get";

export default {
  getDetail(context, { id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/quiz/${id}`)
        .then(response => {
          context.commit("setQuiz", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getCurrent(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/quiz/tests")
        .then(response => {
          context.commit("setQuiz", response.data);
          context
            .dispatch("getTime")
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  },

  getTime(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/quiz/current-time")
        .then(response => {
          const serverDate = new Date(response.data.time);
          serverDate.setHours(serverDate.getHours() - 3);
          context.commit("setTime", serverDate.toISOString());
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  checkCurrent(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/quiz/")
        .then(response => {
          context.commit("setQuiz", get(response, "data[0]"));
          resolve(response);
        })
        .catch(reject);
    });
  },

  finish(context, { id, questions }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/quiz/tests/${id}/finished`, { questions })
        .then(resolve)
        .catch(reject);
    });
  },

  getResult(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/quiz/tests/me/${id}`)
        .then(response => {
          context.commit("setQuiz", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  getMine(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/quiz/tests/me")
        .then(response => {
          context.commit("setQuizzes", response.data.results);
          context
            .dispatch("getTime")
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  },

  answer(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/quiz/answers", data)
        .then(resolve)
        .catch(reject);
    });
  }
};
