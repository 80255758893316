<template>
  <div class="form-group" :class="{ 'has-icon': !!icon }">
    <label :for="id" v-if="label">{{ label }}</label>
    <i v-if="icon" :class="'icon ' + icon"></i>
    <textarea
      v-if="textarea"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />
    <input
      v-else
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <button v-if="withClearButton && value.length" class="clear" @click="clear">
      <i class="icon-x"></i>
    </button>
    <div v-if="showError" class="invalid-feedback">{{ errors[0] }}</div>
  </div>
</template>

<script>
export default {
  props: {
    textarea: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    customClass: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    },
    disableAutocapitalize: {
      type: Boolean,
      default: false
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withClearButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value,
      showError: false
    };
  },
  computed: {
    classObject() {
      const result = { "is-invalid": this.showError };
      result[this.customClass] = true;
      return result;
    }
  },
  watch: {
    errors(newVal) {
      if (newVal && newVal.length) {
        this.showError = true;
      }
    },
    innerValue(newVal) {
      if (newVal != this.value) {
        this.showError = false;
        this.$emit("input", newVal);
      }
    },
    value(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
      }
    }
  },
  methods: {
    clear() {
      this.$emit("input", "");
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  .form-control {
    min-height: 48px;
  }

  &.has-icon {
    .icon {
      position: absolute;
      line-height: 38px;
      left: 4px;
    }

    .form-control {
      padding-left: 32px;
    }
  }

  .clear {
    position: absolute;
    height: 38px;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }
}
</style>
