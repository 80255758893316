<template>
  <form class="lesson-search" @submit.prevent="submit">
    <button type="button" class="btn" v-if="!showing" @click="showing = true">
      <i class="icon-search lead" />
    </button>

    <div
      v-if="showing"
      class="d-flex justify-content-between align-items-center"
    >
      <input
        v-model="text"
        type="text"
        class="form-control"
        placeholder="Pesquisar curso"
      />
      <button type="button" class="btn" @click="close">
        <i class="icon-x lead" />
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      showing: false
    };
  },
  watch: {
    text(value) {
      this.$emit("input", value);
    }
  },
  methods: {
    close() {
      this.showing = false;
      this.text = "";
    },
    submit() {
      this.$router.push({
        name: "search",
        query: { s: this.text }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson-search {
  button {
    max-width: 48px;
  }

  h5 {
    line-height: 38px;
  }
}
</style>
