<template>
  <div class="templates-main-menu">
    <router-link
      :to="{ name: 'home' }"
      class="btn home"
      @click.native="$emit('click-menu')"
    >
      <span>Início</span>
    </router-link>

    <router-link
      :to="{ name: 'formations' }"
      class="btn"
      @click.native="$emit('click-menu')"
    >
      <span>Formações</span>
    </router-link>

    <b-dropdown
      ref="dropdown"
      variant="link"
      class="hide-footer"
      toggle-class="text-decoration-none"
    >
      <template #button-content>
        Cursos
      </template>
      <router-link
        v-for="(course, i) in courses"
        :key="i"
        :to="{ name: 'course', params: { ...course } }"
        class="dropdown-item"
        @click.native="clickDropdown"
      >
        <span>{{ course.title }}</span>
      </router-link>
    </b-dropdown>

    <a href="https://discord.com/invite/wg5WTSrGmj" target="_blank" class="btn">
      <span>Comunidade Discord</span>
    </a>

    <div class="flex-fill"></div>

    <search class="hide-footer" />

    <b-dropdown
      variant="link"
      class="hide-footer"
      toggle-class="text-decoration-none"
    >
      <template #button-content>
        <i class="icon-bell lead"></i>
      </template>
      <b-dropdown-item disabled>Nenhum item para exibir</b-dropdown-item>
    </b-dropdown>

    <router-link
      :to="{ name: 'account' }"
      class="btn"
      @click.native="$emit('click-menu')"
    >
      <span>Minha Conta</span>
    </router-link>

    <a class="btn" @click="$emit('logout')">
      <span>Sair</span>
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Search from "./Search";

export default {
  components: { Search },
  computed: {
    ...mapState("courses", ["courses"])
  },
  methods: {
    clickDropdown() {
      this.$emit("click-menu");
      this.$refs.dropdown.hide(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.templates-main-menu {
  ::v-deep .btn {
    display: flex;
    align-items: center;
  }

  ::v-deep .dropdown-item {
    text-transform: uppercase !important;
    color: #8395a7 !important;
    font-size: 12px;
    font-weight: bold;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
</style>
