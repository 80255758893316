import Vue from "vue";

import FormGroup from "./FormGroup.vue";
import Loading from "./Loading.vue";
import Snackbar from "./Snackbar.vue";
import ActionDispatcher from "./ActionDispatcher.vue";
import Confirmation from "./Confirmation.vue";

Vue.component("form-group", FormGroup);
Vue.component("loading", Loading);
Vue.component("snackbar", Snackbar);
Vue.component("action-dispatcher", ActionDispatcher);
Vue.component("confirmation", Confirmation);
